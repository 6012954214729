import { call, put, takeLatest } from "redux-saga/effects";
import i18n from "./../../i18n";
import * as actions from "../Constants/actionTypes";
import { STATUS_CODE } from "../../Constants/statusCodeApi";
import {
  getUserProfileRequestAction,
  getUserProfileSuccessAction,
  getUserProfileFailureAction,
  updateProfileFailureAction,
  updateProfileSuccessAction,
  changePasswordFailureAction,
  changePasswordSuccessAction,
  uploadAvatarUserFailureAction,
  uploadAvatarUserSuccessAction,
  setUserProfileToNullAction,
} from "../Actions/userAction";
import {
  updateProfileApi,
  getUserProfileApi,
  changePasswordApi,
  uploadAvatarUserApi,
} from "../../Apis/userApi";

import { logoutAction } from "./../Actions/authAction";
import { showLoadingAction, hideLoadingAction } from "../Actions/loadingAction";

export default function* userSaga() {
  yield takeLatest(
    actions.AT_CHANGE_PASSWORD_REQUEST,
    changePasswordRequestSaga
  );
  yield takeLatest(actions.AT_UPDATE_PROFILE_REQUEST, updateProfileRequestSaga);
  yield takeLatest(actions.AT_USER_GET_PROFILE_REQUEST, getUserProfileRequest);
  yield takeLatest(
    actions.AT_UPLOAD_AVATAR_USER_REQUEST,
    uploadAvatarUserRequestSaga
  );
}

function* changePasswordRequestSaga({ payload }) {
  yield put(showLoadingAction());
  const { new_password } = payload.data;
  // var email = getUserEmailFromAccessToken();
  const resp = yield call(changePasswordApi, {
    // email,
    password: new_password,
    ...payload.data,
  });
  const { status } = resp;
  if (status === STATUS_CODE.HTTP_200_OK) {
    const message = i18n.t("Change password successfully!");
    yield put(changePasswordSuccessAction({ message }));
    yield put(logoutAction());
    yield put(setUserProfileToNullAction());
  } else if (status === STATUS_CODE.HTTP_ME_454_OLD_PASSWORD_IS_INCORRECT) {
    const error = i18n.t("Old password is incorrect!");
    yield put(changePasswordFailureAction({ error }));
  } else {
    const error = i18n.t(
      "Can not change password at this time. Please try again!"
    );
    yield put(changePasswordFailureAction({ error }));
  }
  yield put(hideLoadingAction());
}

function* updateProfileRequestSaga({ payload }) {
  yield put(showLoadingAction());
  // var email = getUserEmailFromAccessToken()
  const resp = yield call(updateProfileApi, {
    // email,
    ...payload.data,
  });
  const { status } = resp;
  if (status === STATUS_CODE.HTTP_200_OK) {
    const message = i18n.t("Update profile successfully!");
    yield put(updateProfileSuccessAction(message));
    yield put(getUserProfileRequestAction());
  } else {
    const error = i18n.t(
      "Can not update profile at this time. Please try again!"
    );
    yield put(updateProfileFailureAction({ error }));
  }
  yield put(hideLoadingAction());
}

function* getUserProfileRequest() {
  yield put(showLoadingAction());
  const resp = yield call(getUserProfileApi);
  var { status, data } = resp;
  if (status === STATUS_CODE.HTTP_200_OK) {
    yield put(getUserProfileSuccessAction(data));
  } else {
    const error = i18n.t(
      "Can not get profile data at this time. Please try again!"
    );
    yield put(getUserProfileFailureAction({ error }));
  }
  yield put(hideLoadingAction());
}

function* uploadAvatarUserRequestSaga({ payload }) {
  yield put(showLoadingAction());
  const resp = yield call(uploadAvatarUserApi, payload.data);
  const { status } = resp;
  if (status === STATUS_CODE.HTTP_200_OK) {
    const message = i18n.t("Update successfully!");
    yield put(uploadAvatarUserSuccessAction(message));
    yield put(getUserProfileRequestAction());
  } else {
    const error = i18n.t("Can not update item at this time. Please try again!");
    yield put(uploadAvatarUserFailureAction({ error }));
  }
  yield put(hideLoadingAction());
}
